<template>
  <div class="DistributionMould">
    <div class="leftree">
      <el-input
        v-model="filterText"
        placeholder="学校名称"
        size="small"
        class="search"
      />
      <el-tree
        ref="tree"
        :data="schoolData"
        node-key="id"
        :props="defaultProps"
        @node-click="handleNodeClick"
        :filter-node-method="filterNode"
        :highlight-current="true"
        :current-node-key="1"
      />
    </div>
    <div class="content">
      <el-form ref="searchForm" :inline="true" :model="searchForm">
        <el-form-item label="洗衣机类型">
          <el-select v-model="searchForm.machine_type_id" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="item.val"
              :value="item.id"
              v-for="(item, index) in machineOptions"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="main-form-item-float-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getCorrelationMouldData"
            >查询</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
          <el-button type="primary" @click="handlerDistribute()"
            >分配</el-button
          >
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border stripe>
        <el-table-column
          label="机器类型"
          prop="machineTypeName"
          min-width="120px"
        />
        <el-table-column label="关联模板" prop="name" min-width="120px" />
        <el-table-column
          label="价格内容"
          prop="content"
          align="center"
          min-width="200"
        />
        <el-table-column label="是否删除" prop="isDeleted" min-width="80px">
          <template #default="scope">
            {{ scope.row.isDeleted === "1" ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="80">
          <template #default="scope">
            <el-button @click="handleDel(scope.row.id)" type="text" size="small" v-if="scope.row.isDeleted==0">删除</el-button>
         <!-- <el-button @click="handleResume(scope.row.id)" type="text" size="small" v-else>恢复</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px; text-align: right">
        <el-pagination
          layout="total,sizes,prev, pager, next,jumper,"
          :total="pagination.total"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pagination.pageSize"
          @current-change="handleCurrentPage"
          @size-change="handleSizeChange"
        />
      </div>
    </div>

    <el-dialog
      custom-class="main-el-dialog"
      title="分配"
      v-model="dialogVisible"
      width="500px"
    >
      <el-form
        :model="correlationForm"
        ref="correlationForm"
        label-width="120px"
        style="height: 100px"
      >
        <el-form-item
          label="价格模板"
          prop="id"
          :rules="[
            { required: true, message: '请选择价格模板', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="correlationForm.id"
            placeholder="请选择"
            @change="handlePriceChange"
          >
            <el-option
              :label="item.name + '(' + item.machineTypeName + ')'"
              :value="item.id"
              v-for="(item, index) in correlationMouldOptions"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="agreeCorrelation"
            >确定关联</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { handleCatchStatus } from "../../api/handler";
export default {
  data() {
    return {
      schoolData: [],
      tableData: [],
      defaultProps: {
        children: "children",
        label: "val",
      },
      filterText: "",
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisible: false,

      machineOptions: [],
      searchForm: {
        machine_type_id: "",
      },
      selectKey: "",
      correlationForm: { id: "" },
      correlationMouldOptions: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.getSchoolData();
    this.getMachineTypeData();
  },
  methods: {
    // 获取学校列表
    getSchoolData() {
      this.$api.getSchoolList().then((res) => {
        this.schoolData = res;
        if (this.schoolData.length > 0) {
          this.selectKey = this.schoolData[0].id;
          this.$api
            .getCorrelationMould({ colleageId: this.schoolData[0].id ,is_deleted:0})
            .then((res) => {
              this.tableData = res.list;
            });
        }
      });
    },
    // 获取洗衣机列表
    getMachineTypeData() {
      this.$api.getMachineType().then((data) => {
        this.machineOptions = data;
      });
    },
    // 获取关联模板
    getCorrelationMouldData() {
      let params = {
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
        machine_type_id: this.searchForm.machine_type_id,
        colleageId: this.selectKey,
        is_deleted:0
      };
      this.$api.getCorrelationMould(params).then((res) => {
        this.tableData = res.list;
      });
    },
    // 分页
    handleCurrentPage(current) {
      this.pagination.current = current;
      this.getCorrelationMouldData();
    },
    handleSizeChange(size) {
      this.pagination.pageSize = size;
      this.getCorrelationMouldData();
    },
    // 重置
    resetForm(formName) {
      this.searchForm = { machine_type_id: "" };
      this.getPriceMouldData();
    },
    //分配
    handlerDistribute() {
      this.$api
        .getNotCorrelationMould(this.selectKey)
        .then((res) => {
          this.correlationMouldOptions = res;
          this.dialogVisible = true;
        })
        .catch(handleCatchStatus);
    },
    // 恢复
    handleResume(id){
      this.$api.resumeCorrelationMould(id).then(()=>{
          this.$message.success('恢复成功')
          this.getCorrelationMouldData();
      }).catch(handleCatchStatus);
    },
    // 树选择
    handleNodeClick(data) {
      this.selectKey = data.id;
      this.getCorrelationMouldData();
    },
    // 价格模板选择
    handlePriceChange(id) {
      console.log(id);
    },
    // 确认关联
    agreeCorrelation() {
      this.$refs["correlationForm"].validate((valid) => {
        if (valid) {
          this.$api
            .agreeCorrelationMould({
              'template_id': this.correlationForm.id,
              'colleage_id': this.selectKey,
            })
            .then(() => {
              this.$message.success("关联成功");
              this.dialogVisible=false
              this.getCorrelationMouldData();
            })
            .catch(handleCatchStatus);
        }
      });
    },
    // 数据过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.val.indexOf(value) !== -1;
    },
    // 删除
    handleDel(id) {
      this.$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => this.$api.delCorrelationMould(id))
        .then(() => {
          this.$message.success("删除成功!");
          this.getCorrelationMouldData();
        })
        .catch(handleCatchStatus);
    },
  },
};
</script>

<style  lang="scss" scoped>
.DistributionMould {
  display: flex;
  .leftree {
    flex: 0 0 200px;
    height: 100%;
    padding: 20px;
    border: 1px solid #e1e1e1;
    margin-right: 20px;
    .search {
      margin-bottom: 10px;
    }
  }
  .content {
    flex: 1;
  }
}
</style>